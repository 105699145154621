import { extendTheme } from "@chakra-ui/react";

// Or export `extendBaseTheme` if you only want the default Chakra theme tokens to extend (no default component themes)
export const customTheme = extendTheme({ })

/*
const theme = {
    styles: {
      global: {
        '.mdx-prose': {
          h1: {
            fontSize: 'xl',
            mb: '4',
          },
          p: {
            fontSize: 'sm',
            lineHeight: '1.4',
          },
        },
      },
    },
  }
  */